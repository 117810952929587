@import url(https://fonts.googleapis.com/css2?family=Sanchez&display=swap);
* {
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: "Goudy";
  src: url(/static/media/goudy.61e69a97.woff2);
}

@font-face {
  font-family: "Univers";
  src: url(/static/media/univers.c2a335d3.woff2);
}

:root {
  --brand-orange-1: #f3924b;
  --brand-orange-2: #f07c28;
  --brand-orange-3: #d7620f;
  --brand-dark-grey: #1a1a1a;
  --brand-light-grey: #aaaaaa;

  --fc-page-bg-color: transparent;
  --fc-border-color: var(--brand-light-grey);
  --fc-list-event-hover-bg-color: #aaaaaa33;

  --fc-button-text-color: var(--brand-dark-grey);
  --fc-button-bg-color: var(--brand-orange-1);
  --fc-button-border-color: transparent;
  --fc-button-hover-bg-color: var(--brand-orange-2);
  --fc-button-hover-border-color: transparent;
  --fc-button-active-bg-color: var(--brand-orange-3);
  --fc-button-active-border-color: transparent;
}

.fc {
  z-index: 0;
}

.fc-event {
  border-width: 5px;
  padding: 5px;
  cursor: pointer;
}

.fc-listWeek-view,
.fc-scroller {
  border-radius: 5px;
}

.fc-theme-standard .fc-list {
  border: none;
}

.fc .fc-scrollgrid {
  border: none;
}

.fc .fc-button {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
}

.fc-scrollgrid-section > td,
.fc-scrollgrid-section > th {
  border: none;
}

.fc {
  font-family: "Univers", sans-serif;
}

