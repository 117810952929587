@import url("https://fonts.googleapis.com/css2?family=Sanchez&display=swap");

* {
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: "Goudy";
  src: url("/src/assets/fonts/goudy.woff2");
}

@font-face {
  font-family: "Univers";
  src: url("/src/assets/fonts/univers.woff2");
}
